import request from './request'
import { isIndia, isSouthEast, isBrazil, isMx, isCl } from './config'
import { patternEmail } from '@shein/common-function'
import jumpCountry from 'public/src/pages/common/orderLogic/jumpCountry'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { encryptOest } from '@shein-aidc/basis-oesthandle'
const { SiteUID, appLanguage, lang, currency } = gbCommonInfo

export const paymentType = {
  1: 'Paypal',
  2: 'Bank Account',
  3: 'Paytm',
  4: 'Bank Account'
}

export function checkExcludeSpecialWord(value) {
  return /[~`!@#\$%\^&\*\(\)_\+`\-=\[\]\\{}|;':",\.\/<>\?~！@#￥¥￥$$……&*（）——|{}【】‘；：”“'。，、？]/.test(value)
}

export function tsPaymentTypeToNormal(type) {
  return paymentType[type]
}

export function checkTaxNo(value) {
  if (!value) return false
  const cpfRegexp = /^\d{3}.\d{3}.\d{3}-\d{2}$/
  const cnpjRegexp = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/
  if (cpfRegexp.test(value) || cnpjRegexp.test(value)) {
    return true
  }
  return false
}

export async function requestPageInitInfo(params = {}) {
  const { code, info, language } = await request({
    method: 'GET',
    url: '/api/orders/return/returnPayments/init/get',
    params: {
      i18n: 1
    }
  })

  if (code != 0) {
    return { code, language }
  }

  const oest = UserInfoManager.get({ key: 'oest' })
  const enOest = encryptOest(oest)


  const res = await request({
    url: `/ltspc/transformer/init`,
    method: 'POST',
    data: {
      SiteUID,
      appLanguage,
      lang,
      AppCurrency: currency,
      TokenAes: info.aesToken,
      _csrf: info.csrfToken,
      siteFrom: 'm',
      enOest
    }
  })
  if (res.code != 0) {
    return { code: res.code, language }
  }

  const account = await request({
    url: `/ltspc/account/refund/index`,
    method: 'POST',
    data: params
  })

  if (account.code != 0) {
    return { code: account.code, language }
  }

  const resultCode = account.info && account.info.result && account.info.result
  return { code: resultCode == 1 ? 0 : resultCode, account, language, info }
}

export async function fetchAccountInfo(params = {})  {
  const account = await request({
    url: `/ltspc/account/refund/index`,
    method: 'POST',
    data: params
  })

  if (account.code != 0) {
    return { code: account.code }
  }

  const resultCode = account.info && account.info.result && account.info.result
  return { code: resultCode == 1 ? 0 : resultCode, info: account?.info?.list?.[0] || {} }
}

export async function getAccountInfo(params = {}) {
  const account = await request({
    url: `/ltspc/account/refund/index`,
    method: 'POST',
    data: params
  })
  if (account.code != 0) {
    return { code: account.code }
  }
  const resultCode = account.info && account.info.result && account.info.result
  return { code: resultCode == 1 ? 0 : resultCode, account }
}

// 卡bin校验
async function checkCardBinRule(cardBin) {
  try {
    const { info } = await request({
      url: `/ltspc/account/refund/checkCardBin`,
      method: 'POST',
      timeout: 6 * 1000,
      data: {
        cardBin,
        countryCode: 'AE'
      }
    })
    const code = info && info.result
    const msg = info && info.resultMsg
    if (code == 1) {
      return Promise.resolve()
    } else {
      return Promise.reject(msg)
    }
  } catch (e) {
    return Promise.reject()
  }
}

export function getYearRange([start, end]) {
  let sd = new Date(),
      ed = new Date()
  sd.setFullYear(sd.getFullYear() + start)
  ed.setFullYear(ed.getFullYear() + end)
  const sy = sd.getFullYear()
  const ey = ed.getFullYear()
  return Array.from({ length: ey - sy + 1 }, (_, i) => sy + i)
}

export function getCurrentDate() {
  const nd = new Date()
  return {
    year: nd.getFullYear(),
    month: nd.getMonth() + 1,
    day: nd.getDay() + 1
  }
}

export function getBankAccountRules(matchKeys = [], options = {}) {
  const { langText, area, bankAccountChannel = '', accountType, formData,  isMxDlocal, isClDlocal } = options
  if (['worldpay', 'yuantong', 'dlocal'].includes(bankAccountChannel) || isMxDlocal || isClDlocal) {
    return getSpecialBankRules({
      langText,
      channel: bankAccountChannel,
      matchKeys,
      accountType,
      formData,
      isMxDlocal,
      isClDlocal
    })
  }
  if (area == 'brazil') return getBrazilBankRules(langText)
  const accountErrorMsg =
    area == 'india' ? langText.SHEIN_KEY_PWA_18663 : langText.SHEIN_KEY_PWA_24184
  const accountReg = area == 'india' ? /^\d{1,30}$/ : /^\d{1,20}$/
  const fullRules = {
    cardholderName: [
      {
        required: true,
        type: 'string',
        min: 1,
        max: 100,
        message: langText.SHEIN_KEY_PWA_19887,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_15074
      },
      {
        validator(rule, value) {
          if (
            /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]·！#￥（——）：；“”‘、，|《。》？、【】]/i.test(value)
          ) {
            return Promise.reject(langText.SHEIN_KEY_PWA_19887)
          }
          if (/.*\d|\D{101,}/.test(value)) {
            return Promise.reject(langText.SHEIN_KEY_PWA_19887)
          }
          return Promise.resolve()
        },
        trigger: 'blur'
      }
    ],
    cardholderFirstname: [
      {
        required: true,
        type: 'string',
        min: 1,
        max: 50,
        placeholder: langText.SHEIN_KEY_PWA_33060,
        message: langText.SHEIN_KEY_PWA_33059,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_33058
      },
      {
        validator(rule, value) {
          if (
            /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]·！#￥（——）：；“”‘、，|《。》？、【】]/i.test(value)
          ) {
            return Promise.reject(langText.SHEIN_KEY_PWA_33059)
          }
          if (/.*\d|\D{51,}/.test(value)) {
            return Promise.reject(langText.SHEIN_KEY_PWA_33059)
          }
          return Promise.resolve()
        },
        trigger: 'blur'
      }
    ],
    cardholderLastname: [
      {
        required: true,
        type: 'string',
        min: 1,
        max: 50,
        placeholder: langText.SHEIN_KEY_PWA_33063,
        message: langText.SHEIN_KEY_PWA_33062,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_33061
      },
      {
        validator(rule, value) {
          if (
            /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]·！#￥（——）：；“”‘、，|《。》？、【】]/i.test(value)
          ) {
            return Promise.reject(langText.SHEIN_KEY_PWA_33062)
          }
          if (/.*\d|\D{51,}/.test(value)) {
            return Promise.reject(langText.SHEIN_KEY_PWA_33062)
          }
          return Promise.resolve()
        },
        trigger: 'blur'
      }
    ],
    accountDisplay: [
      {
        required: true,
        type: 'string',
        message: accountErrorMsg,
        pattern: accountReg,
        trigger: 'blur',
        label: area == 'india' ? langText.SHEIN_KEY_PWA_16829 : langText.SHEIN_KEY_PWA_18662
      },
      {
        asyncValidator(rule, value, cb) {
          if (area == 'india') return cb()
          if (!accountReg.test(value)) return cb()
          checkCardBinRule(value.substring(0, 8))
            .then(cb)
            .catch(() => {
              cb(new Error(langText.SHEIN_KEY_PWA_18675))
            })
        },
        trigger: 'blur'
      }
    ],
    ifscCode: [
      { required: true, type: 'string', message: langText.SHEIN_KEY_PWA_16531, trigger: 'blur' },
      { pattern: /^[^\d].*/, message: langText.SHEIN_KEY_PWA_16530, trigger: 'blur' },
      { pattern: /^[^\d].{0,14}$/, message: langText.SHEIN_KEY_PWA_16531, trigger: 'blur' }
    ],
    city: [
      {
        required: true,
        type: 'string',
        pattern: /^[a-zA-Z]{1,60}$/,
        message: langText.SHEIN_KEY_PWA_16541,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_14960
      }
    ],
    expirationDate: [
      {
        type: 'string',
        required: true,
        pattern: /^\d|\\{7}$/,
        message: langText.SHEIN_KEY_PWA_18667,
        trigger: 'blur'
      }
    ]
  }

  return matchKeys.reduce(
    (acc, cur) => ({
      ...acc,
      ...(fullRules[cur] ? { [cur]: fullRules[cur] } : {})
    }),
    {}
  )
}

export function getPaypalRule(field = '', options = {}) {
  const { langText = {} } = options
  return {
    [field]: [
      {
        type: 'string',
        required: true,
        min: 1,
        max: 100,
        message: langText.SHEIN_KEY_PWA_15331
      },
      {
        validator(rule, value) {
          // && !(/paypal\.me\//.test(value))
          if (!patternEmail({ email: value })) {
            return Promise.reject(langText.SHEIN_KEY_PWA_15331)
          }
          return Promise.resolve()
        },
        trigger: 'blur'
      }
    ]
  }
}

export function getPaytmRule(field = '', options = {}) {
  const { langText = {} } = options
  return {
    [field]: [
      {
        type: 'string',
        required: true,
        pattern: /^\d{10}$/,
        message: langText.SHEIN_KEY_PWA_16534,
        trigger: 'blur'
      }
    ]
  }
}

export function isNull(val) {
  return val == null || val === undefined
}

export const paddingZero = val => `${val < 10 ? '0' : ''}${val}`

// 东南亚银行规则
export function getSouthEastAreaBankRules(langText = {}) {
  const accountReg = /^\d{1,50}$/
  const fullRules = {
    cardholderName: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19457,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19461,
        note: langText.SHEIN_KEY_PWA_19906
      },
      {
        pattern: /^[a-zA-Z\d()\-,.]{1,50}$/,
        message: langText.SHEIN_KEY_PWA_19904,
        trigger: 'blur'
      }
    ],
    bankName: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19457,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19462,
        note: langText.SHEIN_KEY_PWA_19905
      },
      {
        pattern: /^[a-zA-Z\d()\-,.]{1,50}$/,
        message: langText.SHEIN_KEY_PWA_19904,
        trigger: 'blur'
      }
    ],
    swiftCode: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19458,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19463
      },
      {
        pattern: /^([\da-zA-Z]{8}|[\da-zA-Z]{11})$/,
        message: langText.SHEIN_KEY_PWA_19458,
        trigger: 'blur'
      }
    ],
    accountDisplay: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19460,
        pattern: accountReg,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19464
      }
    ]
  }
  return fullRules
}

// 检测当前编辑账号归属于哪个区域
export function detectArea(countryCode) {
  return (
    isSouthEast(countryCode) ||
    isIndia(countryCode) ||
    isBrazil(countryCode) ||
    isMx(countryCode) ||
    isCl(countryCode) ||
    { area: '', code: 'AE' }
  )
}
export function getSpecialBankRules(options) {
  const { langText = {}, channel = 'worldpay', accountType, formData, isMxDlocal, isClDlocal } = options
  let fullRules = {}
  if (channel == 'worldpay') {
    fullRules = {
      payee: [
        {
          required: true,
          type: 'string',
          min: 1,
          max: 50,
          message: langText.SHEIN_KEY_PWA_29249,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29247,
          placeholder: langText.SHEIN_KEY_PWA_29248
        },
        {
          pattern: /^[a-zA-Z0-9]{1,50}$/,
          message: langText.SHEIN_KEY_PWA_29249,
          trigger: 'blur'
        }
      ],
      bankName: [
        {
          required: true,
          type: 'string',
          message: langText.SHEIN_KEY_PWA_29249,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_19462,
          min: 1,
          max: 50,
          placeholder: langText.SHEIN_KEY_PWA_29250
        },
        {
          pattern: /^[a-zA-Z0-9]{1,50}$/,
          message: langText.SHEIN_KEY_PWA_29249,
          trigger: 'blur'
        }
      ],
      swiftBic: [
        {
          required: true,
          type: 'string',
          min: 8,
          max: 11,
          message: langText.SHEIN_KEY_PWA_29255,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29251,
          placeholder: langText.SHEIN_KEY_PWA_29252
        },
        {
          pattern: /^([\da-zA-Z]{8}|[\da-zA-Z]{11})$/,
          message: langText.SHEIN_KEY_PWA_29255,
          trigger: 'blur'
        }
      ],
      accountNumber: [
        {
          required: true,
          type: 'string',
          min: 1,
          max: 60,
          message: langText.SHEIN_KEY_PWA_29246,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_19464,
          placeholder: langText.SHEIN_KEY_PWA_29245
        },
        {
          pattern: /^\d{1,60}$/,
          message: langText.SHEIN_KEY_PWA_29246,
          trigger: 'blur'
        }
      ]
    }
  } else if (channel == 'yuantong') {
    fullRules = {
      accountName: [
        {
          required: true,
          type: 'string',
          min: 1,
          max: 60,
          message: langText.SHEIN_KEY_PWA_29256,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29253,
          placeholder: langText.SHEIN_KEY_PWA_29254
        },
        {
          validator(rule, value) {
            if (checkExcludeSpecialWord(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_29256)
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      bankName: [
        {
          required: true,
          type: 'string',
          message: langText.SHEIN_KEY_PWA_29259,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29257,
          min: 1,
          max: 30,
          placeholder: langText.SHEIN_KEY_PWA_29258
        },
        {
          validator(rule, value) {
            if (checkExcludeSpecialWord(value) || /\d/.test(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_29259)
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      branchName: [
        {
          required: false,
          type: 'string',
          min: 1,
          max: 30,
          message: langText.SHEIN_KEY_PWA_29262,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29260,
          placeholder: langText.SHEIN_KEY_PWA_29261
        },
        {
          validator(rule, value) {
            if (checkExcludeSpecialWord(value) || /\d/.test(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_29262)
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      accountNumber: [
        {
          required: true,
          type: 'string',
          min: 1,
          max: 14,
          message: langText.SHEIN_KEY_PWA_29424,
          trigger: 'blur',
          label: langText.SHEIN_KEY_PWA_29263,
          placeholder: langText.SHEIN_KEY_PWA_29264
        },
        {
          validator(rule, value) {
            if (!/^[0-9]{1,14}$/.test(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_29424)
            }

            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ]
    }
  } else if (channel == 'dlocal' || isMxDlocal) {
    fullRules = {
      bankAccountType: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_31970}*`,
          placeholder: langText.SHEIN_KEY_PWA_31975,
          message: langText.SHEIN_KEY_PWA_31979,
        },
      ],
      cardholderName: [
        {
          required: true,
          type: 'string',
          message: langText.SHEIN_KEY_PWA_31979,
          placeholder: langText.SHEIN_KEY_PWA_31976,
          label: `${langText.SHEIN_KEY_PWA_31971}*`,
          pattern: /^.{1,100}$/
        },
        {
          validator(rule, value) {
            if (
              /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]·！#￥（——）：；“”‘、，|《。》？、【】]/i.test(value)
              || /^\d+$/i.test(value)
            ) {
              return Promise.reject(langText.SHEIN_KEY_PWA_31979)
            }
            if (/^.{101,}$/i.test(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_31979)
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      account: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_18872}*`,
          placeholder: langText.SHEIN_KEY_PWA_31977,
          message: langText.SHEIN_KEY_PWA_31980,
        },
        {
          validator(rule, value) {
            if (formData.accountDisplay == value && formData.bankAccountType == formData.initialType) {
              // (脱敏数据)未编辑卡号，未切换类型，不校验
              return Promise.resolve()
            }
            if (accountType == 'CLABE') {
              if (!/^[0-9]{18}$/.test(value)) {
                return Promise.reject(langText.SHEIN_KEY_PWA_31980)
              }
            } else if (accountType == 'Debit Card') {
              if (!/^[0-9]{16}$/.test(value)) {
                return Promise.reject(langText.SHEIN_KEY_PWA_31980)
              }
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      bankName: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_19462}*`,
          placeholder: langText.SHEIN_KEY_PWA_31978,
          message: langText.SHEIN_KEY_PWA_31981,
        },
        {
          validator() {
            if (!formData.bankAccountType && !formData.bankAccountType.bankCardNumberLength) {
              // bankAccountType为空且卡号位数为空，不校验
              return Promise.resolve()
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
    }
  }else if(channel == 'cl_dlocal' || isClDlocal) {
    fullRules = {
      taxNo: [
        {
          required: true,
          type: 'string',
          message: langText.SHEIN_KEY_PWA_35074,
          placeholder: langText.SHEIN_KEY_PWA_35082,
          label: `${langText.SHEIN_KEY_PWA_35073}*`,
        }, {
          validator(rule, value) {
            if (value && value == formData?.originalTaxNo) {
              // (脱敏数据)未编辑税号,不校验
              return Promise.resolve()
            }
            // 移除所有非字母和非数字的字符
            const cleanRut = value.replace(/[^a-zA-Z0-9]/g, '')
  
            if (cleanRut.length < 8 || cleanRut.length > 9) {
              return Promise.reject(langText.SHEIN_KEY_PWA_35074)
            }

            const checkVector = [3, 2, 7, 6, 5, 4, 3, 2]
            const docToCheck = cleanRut.padStart(9, '0')
            const docWithoutCheckAux = docToCheck.slice(0, -1)
            const docWithoutCheck = docWithoutCheckAux.split('')
            const verifCode = docToCheck.slice(-1);

            let sum = 0
            for (let i = 0; i < 8; ++i) {
              sum += checkVector[i] * parseInt(docWithoutCheck[i], 10)
            }

            const checkDigitToVerifyAux = sum % 11
            let checkDigitToVerify = 11 - checkDigitToVerifyAux

            if (checkDigitToVerify == 10) {
              checkDigitToVerify = 'k'
            } else if (checkDigitToVerify == 11) {
              checkDigitToVerify = '0'
            }

            const result = verifCode.toLowerCase() === checkDigitToVerify.toString()

            if (result) {
              return Promise.resolve()
            }
            return Promise.reject(langText.SHEIN_KEY_PWA_35074)
          },
          trigger: 'blur'
        }
      ],
      cardholderName: [
        {
          required: true,
          type: 'string',
          message: langText.SHEIN_KEY_PWA_31979,
          placeholder: langText.SHEIN_KEY_PWA_31976,
          label: `${langText.SHEIN_KEY_PWA_31971}*`,
          pattern: /^.{1,100}$/
        },
        {
          validator(rule, value) {
            if (
              /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]·！#￥（——）：；“”‘、，|《。》？、【】]/i.test(value)
              || /^\d+$/i.test(value)
            ) {
              return Promise.reject(langText.SHEIN_KEY_PWA_31979)
            }
            if (/^.{101,}$/i.test(value)) {
              return Promise.reject(langText.SHEIN_KEY_PWA_31979)
            }
            return Promise.resolve()
          },
          trigger: 'blur'
        }
      ],
      bankName: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_19462}*`,
          placeholder: langText.SHEIN_KEY_PWA_31978,
          message: langText.SHEIN_KEY_PWA_31981,
        }
      ],
      account: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_18872}*`,
          placeholder: langText.SHEIN_KEY_PWA_31977,
          message: langText.SHEIN_KEY_PWA_31980,
        },
        {
          validator(rule, value) {
            if (formData.accountDisplay == value && formData.bankAccountType == formData.initialType) {
              // (脱敏数据)未编辑卡号,不校验
              return Promise.resolve()
            }
            if (value.length <= 45 && /^\d+$/.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(langText.SHEIN_KEY_PWA_31980)

          },
          trigger: 'blur'
        }
      ],
      bankAccountType: [
        {
          required: true,
          type: 'string',
          label: `${langText.SHEIN_KEY_PWA_31970}*`,
          placeholder: langText.SHEIN_KEY_PWA_31975,
          message: langText.SHEIN_KEY_PWA_31979,
        },
      ],
    }
  }

  return fullRules
}
// 巴西银行规则
export function getBrazilBankRules(langText = {}) {
  const fullRules = {
    bankList: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19884,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19462
      }
    ],
    accountDisplay: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19885,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19464,
        pattern: /^(\d{1,18}|\d{1,15}\-\w{1,2})$/
      }
    ],
    bankAccountType: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19886,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19889
      }
    ],
    cardholderName: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19887,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_15074,
        pattern: /^.{1,100}$/
      }
    ],
    taxNo: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_20099,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_19890,
        pattern: /^\w{1,30}$/
      }
    ],
    bankBranch: [
      {
        required: true,
        type: 'string',
        message: langText.SHEIN_KEY_PWA_19883,
        trigger: 'blur',
        label: langText.SHEIN_KEY_PWA_20036,
        pattern: /^(\d{1,5}|\d{1,5}\-\d{1})$/
      }
    ]
  }
  return fullRules
}

// 账户类型-Email校验规则
const getEmailItemRules = (options = {}) => {
  const { langText = {}, defaultData = {} } = options
  return [
    {
      type: 'string',
      required: true,
      min: 1,
      max: 100,
      trigger: 'blur',
      message: langText.SHEIN_KEY_PWA_26126
    },
    {
      validator(rule, value) {
        const { field } = rule
        // 编辑未改变不做校验
        if (defaultData[field] && value == defaultData[field]) {
          return Promise.resolve()
        }
        if (!patternEmail({ email: value })) {
          return Promise.reject(langText.SHEIN_KEY_PWA_26126)
        }
        return Promise.resolve()
      },
      trigger: 'blur'
    }
  ]
}

// 账户类型-Phone校验规则
const getPhoneItemRules = (options = {}) => {
  const { langText = {}, defaultData = {} } = options
  return [
    {
      type: 'string',
      required: true,
      trigger: 'blur',
      validator(rule, value) {
        const { field } = rule
        // 编辑未改变不做校验
        if (defaultData[field] && value == defaultData[field]) {
          return Promise.resolve()
        }
        if (!/^\d{10,11}$/.test(value)) {
          return Promise.reject(langText.SHEIN_KEY_PWA_26127)
        }
        return Promise.resolve()
      }
    }
  ]
}

// 账户类型-Document_id校验规则
const getDocumentIdRules = (options = {}) => {
  const { langText = {}, defaultData = {} } = options
  return [
    {
      required: true,
      type: 'string',
      min: 1,
      max: 30,
      trigger: 'blur',
      message: langText.SHEIN_KEY_PWA_26121
    },
    {
      validator(rule, value) {
        const { field } = rule
        // 编辑未改变不做校验
        if (defaultData[field] && value == defaultData[field]) {
          return Promise.resolve()
        }
        if (!checkTaxNo(value)) {
          return Promise.reject(langText.SHEIN_KEY_PWA_26121)
        }
        // else if(value == '') {
        // 	return Promise.reject(langText.SHEIN_KEY_PWA_26122)
        // }
        return Promise.resolve()
      },
      trigger: 'blur'
    }
  ]
}

// 账户类型-Bank_account校验规则
const getBankAccountItemRules = (options = {}) => {
  const { langText = {} } = options
  return [
    {
      required: true,
      type: 'string',
      min: 1,
      max: 50,
      trigger: 'blur',
      message: langText.SHEIN_KEY_PWA_26128
    }
  ]
}

export function getPixRules(matchKeys = [], options = {}) {
  const { langText = {}, pixFormData = {} } = options
  const { transferAccountType } = pixFormData

  const fullRules = {
    taxNo: getDocumentIdRules(options),
    cardholderName: [
      {
        required: true,
        type: 'string',
        min: 1,
        max: 50,
        trigger: 'blur',
        message: langText.SHEIN_KEY_PWA_26124
      }
    ],
    transferAccountType: [
      {
        required: true,
        type: 'string',
        trigger: 'blur',
        message: langText.SHEIN_KEY_PWA_15047
      }
    ],
    account: []
  }
  if (transferAccountType) {
    if (transferAccountType == '1') {
      fullRules.account = getEmailItemRules(options)
    } else if (transferAccountType == '3') {
      fullRules.account = getPhoneItemRules(options)
    } else if (transferAccountType == '5') {
      fullRules.account = getDocumentIdRules(options)
    } else if (transferAccountType == '6') {
      fullRules.account = getBankAccountItemRules(options)
    }
  }

  return matchKeys.reduce(
    (acc, cur) => ({
      ...acc,
      ...(fullRules[cur] ? { [cur]: fullRules[cur] } : {})
    }),
    {}
  )
}

export function formatAccountParams(accounts_list = []) {
  const accountsList = accounts_list || []
  const accountInfo = accountsList.reduce((prev, cur) => {
    if (cur.alias_type == 1 && cur.alias) {
      prev.email = cur.alias
    } else if (cur.alias_type == 2 && cur.alias) {
      prev.phone = cur.alias
      prev.areaCode = cur.area_code
    }
    return prev
  }, {})
  return {
    info: {
      risk_info: {
        extend_info: {
          actions: [
            {
              name: accountInfo.phone ? 'phone_captcha' : 'email_captcha',
              target: accountInfo
            }
          ]
        }
      }
    }
  }
}

// 获取当前站点的国家信息
export async function getSiteCountry () {
  const locationList = await jumpCountry.getLocationList()
  const matchCountry = locationList?.find(item => item.siteUid === SiteUID)
  return { area: matchCountry?.country || '', code: matchCountry?.value || 'AE' }
}
